import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, Typography, Snackbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavLink from './NavLink';
import backgroundImage from '../Imgs/home.jpg';
import logo from '../Imgs/Magnet_logo-removebg.png';
import '../Css/Header_css.css';
// christmas start
import Snowfall from 'react-snowfall';
import ChristmasIcon from '../Imgs/christmas-icon.png';
// christmas end
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getDocs, query, collection, where } from 'firebase/firestore';
function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [promoOpen, setPromoOpen] = useState(true); // Control promo banner visibility christmas

  // christmas
  const handlePromoClose = () => {
    setPromoOpen(false);
  };
  //
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        setScrolled(window.scrollY > 50);
      };

      window.addEventListener('scroll', handleScroll);
      console.log('Scroll listener added');

      return () => {
        window.removeEventListener('scroll', handleScroll);
        console.log('Scroll listener removed');
      };
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userQuery = query(collection(db, 'users'), where('email', '==', user.email));
        const querySnapshot = await getDocs(userQuery);
        const userDocId = querySnapshot.docs[0].id;
        const userDoc = doc(db, 'users', userDocId);
        const userSnap = await getDoc(userDoc);

        if (userSnap.exists()) {
          const data = userSnap.data();
          setIsAdmin(data.role === 'admin');
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const drawer = (
    <div className="drawer-list">
      <NavLink to="home-container" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Home
      </NavLink>
      <NavLink to="/signin" isExternal={true} scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Sign In
      </NavLink>
      {isAdmin && (
      <NavLink to="/checkOrder" isExternal={true} scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
      Check Orders
      </NavLink>
      )}
      <NavLink to="/order" isExternal={true} scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Order
      </NavLink>
      <NavLink to="gallery-section" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Gallery
      </NavLink>
      <NavLink to="reviews-section" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        Reviews
      </NavLink>
      <NavLink to="about-section" scrolled={scrolled} isDrawer={true} onClick={handleDrawerToggle}>
        About Us
      </NavLink>
    </div>
  );

  return (
    <Box>
      {/* christmas */}
      <Snowfall color="white" snowflakeCount={150} style={{ position: 'absolute', zIndex: 1 }} />
      <Snackbar
  open={promoOpen}
  onClose={handlePromoClose}
  message="🎄 Christmas Offer: 20% off all orders! 🎁"
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  sx={{
    backgroundColor: 'green',
    color: 'white',
    fontSize: { xs: '0.7rem', sm: '0.9rem' }, // Smaller font size for mobile
    padding: { xs: '4px 10px', sm: '6px 15px' }, // Compact padding
    textAlign: 'center',
    maxWidth: '90%', // Ensure it doesn’t take too much space
    width: 'fit-content', // Prevent it from stretching unnecessarily
    borderRadius: '8px', // Rounded corners
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
    position: 'fixed', // Ensure it’s independent of other elements
    top: { xs: '15%', sm: '10%' }, // Adjust top positioning for visibility
    zIndex: 1400, // Higher z-index to stay above other components
    left: '50%', // Center horizontally
    transform: 'translateX(-50%)', // Center the snackbar
  }}
/>
      {/* christmas */}
      <Box
        sx={{
          minHeight: '100vh',
          backgroundImage: `url(${ChristmasIcon})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          color: '#FFFFFF',
        }}
        className="header-box"
      >
        <AppBar position="fixed" className={`app-bar ${scrolled ? 'scrolled' : ''}`}>
          <Toolbar className="toolbar">
            <IconButton
              edge="start"
              color="inherit"
              component={NavLink}
              to="/"
              sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
            >
              <img src={logo} alt="Logo" className="logo" />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <NavLink to="home-section" scrolled={scrolled}>
                Home
              </NavLink>
              <NavLink to="/signin" isExternal={true} scrolled={scrolled}>
                Sign In
              </NavLink>
              {isAdmin && (
              <NavLink to="/checkOrder" isExternal={true} scrolled={scrolled}>
                Check Orders
              </NavLink>
              )}
              <NavLink to="/order" isExternal={true} scrolled={scrolled}>
                Order
              </NavLink>
              <NavLink to="gallery-section" scrolled={scrolled}>
                Gallery
              </NavLink>
              <NavLink to="reviews-section" scrolled={scrolled}>
                Reviews
              </NavLink>
              <NavLink to="about-section" scrolled={scrolled}>
                About Us
              </NavLink>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <IconButton edge="end" color="inherit" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: 'drawer-paper' }}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
        <Box 
          className="hero-box" 
          sx={{ 
            textAlign: 'center', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: '10px', // Space between the two texts
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            sx={{
              color: 'white',
              fontFamily: '"Tangerine", cursive',
              fontSize: { xs: '3rem', sm: '5rem' },
            }}
          >
            Keep Your Memories Magnified
          </Typography>

          <Typography
  variant="h2"
  component="h2"
  sx={{
    color: 'red', // Bright red color
    fontFamily: '"Times New Roman", serif',
    fontSize: { xs: '2.5rem', sm: '4rem', md: '6rem' }, // More responsive options
    fontWeight: 'bold',
    letterSpacing: '2px',
  }}
>
   20% Off 
</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
